import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import FormattedHeadline from "@/components/FormattedHeadline";
import { renderMarkdown } from "@/utils/helpers/markdown";

import styles from "./HeroText.module.scss";
import LeftUserInteraction from "./LeftUserInteraction";

const quoters = (text) => {
  if (typeof text === "string") {
    return text.replaceAll("'", "’");
  }

  return text;
};

const HeroText = ({
  className,
  hasBackgroundShade,
  headline,
  headlineAlignment,
  headlineDesktop,
  headlineDesktopSize,
  headlineMobile,
  headlineMobileSize,
  isCompact,
  primaryCtaStyles,
  subcopy,
  subheading,
  type,
  userInteraction,
}) => {
  const positionContent =
    headlineAlignment !== "Default" && headlineAlignment
      ? headlineAlignment.toLowerCase()
      : "";

  const displayUserInteractionType = (type, userInteraction) => {
    let interaction = <div />;
    if (type === "componentHeroCtAs") {
      interaction = (
        <LeftUserInteraction
          headlineAlignment={headlineAlignment}
          positionContent={positionContent}
          primaryCtaStyles={primaryCtaStyles}
          userInteraction={userInteraction}
        />
      );
    }

    return interaction;
  };

  const styleObj = {
    textAlign: positionContent,
    margin: positionContent === "center" ? "0px auto" : "0px",
  };

  if (positionContent === "right") {
    styleObj["marginLeft"] = "auto";
  }

  if (headline) {
    return (
      <header
        className={classNames(styles.heroText, className)}
        data-background-shade={hasBackgroundShade}
        data-compact={isCompact}
      >
        <h1 className={styles.headline}>{renderMarkdown(headline)}</h1>

        <p className={styles.subheading}>{renderMarkdown(subheading)}</p>

        {userInteraction && type === "componentHeroCtAs" && (
          <LeftUserInteraction
            className={styles.cta}
            headlineAlignment={headlineAlignment}
            positionContent="unset"
            userInteraction={userInteraction}
          />
        )}
      </header>
    );
  }

  return (
    <div
      className={`${styles.header} col-md-11`}
      style={{
        textAlign: positionContent,
      }}
    >
      {headlineDesktop && (
        <h1
          className={`${styles.headlineElementDesktop} ${
            styles[`Desktop-${headlineDesktopSize}`]
          } d-xl-block d-lg-block d-md-none d-sm-none`}
        >
          <FormattedHeadline richText={headlineDesktop} />
        </h1>
      )}
      {headlineMobile && (
        <h1
          className={`${styles.headlineElementMobile} ${
            styles[`Mobile-${headlineMobileSize}`]
          } d-xl-none d-lg-none d-md-block`}
        >
          <FormattedHeadline richText={headlineMobile} />
        </h1>
      )}

      {subcopy && (
        <p
          className={`my-5 ${
            positionContent !== "center" ? "col-xl-8 col-lg-16" : ""
          } p-0 ${styles.subcopy}`}
          style={styleObj}
        >
          {quoters(subcopy)}
        </p>
      )}

      {userInteraction && displayUserInteractionType(type, userInteraction)}
    </div>
  );
};

HeroText.propTypes = {
  className: PropTypes.string,
  hasBackgroundShade: PropTypes.bool,
  headline: PropTypes.string,
  headlineAlignment: PropTypes.string,
  headlineDesktop: PropTypes.object,
  headlineDesktopSize: PropTypes.string,
  headlineMobile: PropTypes.object,
  headlineMobileSize: PropTypes.string,
  isCompact: PropTypes.bool,
  primaryCtaStyles: PropTypes.string,
  subcopy: PropTypes.string,
  subheading: PropTypes.string,
  type: PropTypes.string.isRequired,
  userInteraction: PropTypes.array.isRequired,
};

export default HeroText;
